import React, { useEffect, useState } from "react";

import { useMutation, useQuery } from "react-query";

import {
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import ReactSelect from "react-select";
import { differenceInYears } from "date-fns"; // Importing date-fns to easily calculate date differences

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoAvatarIcon from "@mui/icons-material/AccountCircle";
import OpenIcon from "@mui/icons-material/OpenInNew";

import Box from "@mui/material/Box";
import { Button } from "@mui/material";

import ResetPasswordDialog from "./ResetPasswordDialog";
import ErrorAlert from "../errorAlert";
import ContainerBox from "../Container";

import {
  getGeduDetails,
  updateUser,
  updateGeduProfile,
  setCriminalRecordStatusChecked,
  setPassword,
} from "../Hooks/users";

import { getCodeDomainsByNames } from "../Hooks/codes";

import { setFilteringData } from "../Hooks/gedus";
import { getAllMunicipalities } from "../Hooks/municipality";

import { useTranslation } from "react-i18next";
import { isAdmin } from "../Util/authHelper";
import {
  prepareClubName,
  prepareClubMeetingTimes,
  formatDate,
} from "../Util/clubHelper";
import GeneralTextFieldEditor from "../Util/GeneralTextFieldEditor";
import { de } from "date-fns/locale";
import { set } from "date-fns";

export default function GeduDetails(params) {
  const { t } = useTranslation();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const geduId = urlParams.get("id");
  const [userData, setUserData] = useState({});
  const [ownClubsData, setOwnClubsData] = useState([]);
  const [geduBillingMethods, setGeduBillingMethods] = useState([]);
  const [geduLocations, setGeduLocations] = useState([]); // [ {value: "id", label: "name"}, ...]
  const [geduLanguages, setGeduLanguages] = useState([]);
  const [geduSkills, setGeduSkills] = useState([]);
  const [geduQualifications, setGeduQualifications] = useState([]);
  const [geduAttendanceTypes, setGeduAttendanceTypes] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedQualifications, setSelectedQualification] = useState([]);
  const [selectedAttendanceTypes, setSelectedAttendanceTypes] = useState([]);
  const [selectedInvoicingMethod, setSelectedInvoicingMethod] = useState("");
  const [changeBillingMethodOpen, setChangeBillingMethodOpen] = useState(false);
  const [changeWebflowOpen, setChangeWebflowOpen] = useState(false);

  const [webflowUpdateStatusText, setWebflowUpdateStatusText] = useState("");
  const [webflowUpdateSuccess, setWebflowUpdateSuccess] = useState(true);

  const [changeFilteringDataOpen, setFilteringDataOpen] = useState(false);
  const [filteringDataError, setFilteringDataError] = useState("");

  const { data, error, isLoading, refetch, isFetching } = useQuery(
    ["geduId", geduId],
    () => getGeduDetails(geduId)
  );

  const { mutate: mutateUpdate, error: errorUpdate } = useMutation(updateUser);
  const {
    mutate: mutateUpdateProfile,
    error: errorUpdateProfile,
    isLoading: profileDataIsLoading,
  } = useMutation(updateGeduProfile);
  const {
    mutate: mutateFilteringData,
    error: errorFilteringDataUpdate,
    isLoading: filteringDataIsLoading,
  } = useMutation(setFilteringData);

  const {
    mutate: mutateCriminalRecordStatusChecked,
    error: errorCriminalRecordStatusChecked,
    isLoading: criminalRecordStatusIsLoading,
  } = useMutation(setCriminalRecordStatusChecked);

  // The names of the needed CodeDomains
  const codeDomainNames = [
    "GEDU_BILLING_METHOD",
    "GEDU_QUALIFICATION",
    "CLUB_LANGUAGE",
    "CLUB_CONTENT_TYPE", // gedu skills
    "CLUB_ATTANDANCE_TYPE",
  ];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
    refetch: refetchDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  const {
    data: dataMunicipalities,
    error: errorMunicipalities,
    isLoading: isLoadingMunicipalities,
    refetch: refetchMunicipalities,
  } = useQuery(["municipalities"], getAllMunicipalities);

  const appUserRoles = params?.appAuthInfo?.appUserRoles;

  useEffect(() => {
    if (data) {
      console.log(data);
      prepareUserData(data);
    }
  }, [data]);

  useEffect(() => {
    if (dataCodeDomains) createCodeDomains();
  }, [dataCodeDomains]);

  useEffect(() => {
    if (dataMunicipalities) createMunicipalities();
  }, [dataMunicipalities]);

  const createCodeDomains = () => {
    console.log("geduDetails.createCodeDomains");
    console.log(dataCodeDomains);

    const geduBillingMethods = [];
    const geduQualifications = [];
    const geduSkills = [];
    const clubLanguages = [];
    const geduAttendanceTypes = [];
    //        "GEDU_QUALIFICATION", "CLUB_LANGUAGE", "CLUB_CONTENT_TYPE";
    dataCodeDomains.forEach((codeDomain) => {
      if (codeDomain.name == "GEDU_BILLING_METHOD") {
        codeDomain.codes.forEach((code) => {
          geduBillingMethods.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      } else if (codeDomain.name == "GEDU_QUALIFICATION") {
        codeDomain.codes.forEach((code) => {
          geduQualifications.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      } else if (codeDomain.name == "CLUB_LANGUAGE") {
        codeDomain.codes.forEach((code) => {
          clubLanguages.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      } else if (codeDomain.name == "CLUB_CONTENT_TYPE") {
        codeDomain.codes.forEach((code) => {
          geduSkills.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      } else if (codeDomain.name == "CLUB_ATTANDANCE_TYPE") {
        codeDomain.codes.forEach((code) => {
          geduAttendanceTypes.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      }
    });

    geduBillingMethods.sort((a, b) => a.index - b.index);
    geduQualifications.sort((a, b) => a.index - b.index);
    geduSkills.sort((a, b) => a.index - b.index);
    clubLanguages.sort((a, b) => a.index - b.index);
    geduAttendanceTypes.sort((a, b) => a.index - b.index);

    console.log(clubLanguages);

    setGeduBillingMethods(geduBillingMethods);
    setGeduQualifications(geduQualifications);
    setGeduSkills(geduSkills);
    setGeduLanguages(clubLanguages);
    setGeduAttendanceTypes(geduAttendanceTypes);
  };

  const createMunicipalities = () => {
    console.log("geduDetails.createMunicipalities");
    console.log(dataMunicipalities);

    const municipalities = [];
    for (const municipality of dataMunicipalities.municipalities) {
      const aMunicipality = {
        value: municipality.id,
        label: municipality.name,
      };
      municipalities.push(aMunicipality);
    }

    municipalities.sort((a, b) => a.label.localeCompare(b.label));

    setGeduLocations(municipalities);
  };

  const prepareUserData = (gedu) => {
    const cardData = {
      id: gedu.id,
      name: gedu.lastName + ", " + gedu.firstName,
      webflowName: gedu.firstName + " " + gedu.lastName,
      firstName: gedu.firstName,
      lastName: gedu.lastName,
      email: gedu.email,
      phoneNumber: gedu.phoneNumber,
      discordUserId: gedu.discordUserId,
      discordUserName: gedu.discordUserName,
      avatar: gedu.discordAvatar ? gedu.discordAvatar : "",
      lastLogin: gedu.lastLogin
        ? formatDate(new Date(gedu.lastLogin), "YYYY-MM-DD hh:mm.ss")
        : t("geduDetails.lastLoginNever"),

      invoicingData: {
        invoicingMethodId: gedu.geduProfile?.billingMethodId
          ? gedu.geduProfile.billingMethodId
          : "", // code from dropdown
        invoicingMethodName: gedu.geduProfile?.billingMethod
          ? t("code." + gedu.geduProfile?.billingMethod.name)
          : t("geduDetails.invoicingMethodNotSelected"),
        invoicingEmail: gedu.geduProfile?.invoicingEmail
          ? gedu.geduProfile.invoicingEmail
          : "",
      },

      isActive: gedu.geduProfile?.isActive ? gedu.geduProfile.isActive : false,
      isExperienceArchitect: gedu.geduProfile?.isExperienceArchitect,
      publicDescription: gedu.geduProfile?.publicDescription,
      publicImageLink: gedu.geduProfile?.publicImageLink,
      webflowGeduId: gedu.geduProfile?.webflowGeduId,
    };

    // languages
    const selectedLanguages = [];
    for (const language of gedu.geduLanguages) {
      selectedLanguages.push({
        value: language.id,
        label: t("code." + language.name),
      });
    }
    setSelectedLanguages(selectedLanguages);

    const geduSkills = [];
    for (const skill of gedu.geduSkills) {
      geduSkills.push({
        value: skill.id,
        label: t("code." + skill.name),
      });
    }
    setSelectedSkills(geduSkills);

    const geduLocations = [];
    for (const location of gedu.geduLocations) {
      geduLocations.push({
        value: location.id,
        label: location.name,
      });
    }
    setSelectedLocations(geduLocations);

    const geduQualifications = [];
    for (const qualification of gedu.geduQualifications) {
      geduQualifications.push({
        value: qualification.id,
        label: t("code." + qualification.name),
      });
    }
    setSelectedQualification(geduQualifications);

    const geduAttendanceTypes = [];
    for (const attandanceType of gedu.geduAttandanceTypes) {
      geduAttendanceTypes.push({
        value: attandanceType.id,
        label: t("code." + attandanceType.name),
      });
    }
    setSelectedAttendanceTypes(geduAttendanceTypes);

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>GEDU PROFILE");
    console.log(gedu.geduProfile);

    // localize the roles
    const localizedItems = gedu.roles.map((item) => t("role." + item));

    // Join the localized items into a comma-delimited string
    const roleString = localizedItems.join(", ");

    cardData.roles = roleString;

    // 20240812: Added the info of the criminal record check
    if (gedu.criminalRecordCheck && gedu.criminalRecordCheck.status) {
      const checkedAtDate = new Date(gedu.criminalRecordCheck.checkedAt);
      // Calculate the difference in years between the checkedAt date and now
      const yearsDifference = differenceInYears(new Date(), checkedAtDate);
      // Determine the text color based on the difference
      const textColor = yearsDifference < 1 ? "green" : "red";

      cardData.criminalRecordCheck = {
        status: true,
        checkedAt: formatDate(
          gedu.criminalRecordCheck.checkedAt,
          "YYYY-MM-DD hh:mm.ss"
        ),
        firstName: gedu.criminalRecordCheck.checkUserFirstName,
        lastName: gedu.criminalRecordCheck.checkUserLastName,
        textColor: textColor,
      };
    } else {
      cardData.criminalRecordCheck = {
        status: false,
      };
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>CARD DATA");
    console.log(cardData);

    setUserData(cardData);

    const clubs = [];
    // Prepare the own clubs data
    for (const club of gedu.ownClubs) {
      const aClub = {
        id: club.id,
        name: prepareClubName(club),
        meetings: prepareClubMeetingTimes(club, t, true)?.join(", "),
        role: t("geduDetails.clubRole.owner"),
      };
      clubs.push(aClub);
    }

    // Prepare the assigned clubs data
    for (const club of gedu.assignedClubs) {
      const aClub = {
        id: club.id,
        name: prepareClubName(club),
        meetings: prepareClubMeetingTimes(club, t, true)?.join(", "),
        role: t("geduDetails.clubRole." + club.assignmentType),
        roleType: club.assignmentType,
        groupName: club.groupName,
      };
      clubs.push(aClub);
    }

    setOwnClubsData(clubs);
  };

  const openClub = (clubId) => {
    window.open(`/club/?id=${clubId}`, "_blank").focus();
  };

  const updateUserData = (data) => {
    const body = {};
    Object.keys(data).forEach((key) => {
      body[key] = data[key].value;
    });

    mutateUpdate(
      {
        id: geduId,
        body: body,
      },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };

  const updateGeduProfileData = (data, updateWebflow = false) => {
    setWebflowUpdateStatusText("");
    console.log(data);
    const body = {};
    Object.keys(data).forEach((key) => {
      console.log("key: " + key);
      console.log("value: " + data[key]);

      if (data[key] !== "") {
        body[key] = data[key];
      }
    });

    if (updateWebflow) {
      // 20240826: disable the webflow update for now --> causing issues on API end
      //body["updateWebflow"] = true; // set the flag to update webflow
    }

    console.log(body);
    mutateUpdateProfile(
      {
        id: geduId,
        body: body,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          if (body.updateWebflow) {
            // get webflow succes
            const success = e.webflow.success;
            console.log("Webflow success: " + success);
            setWebflowUpdateSuccess(success); // set status from response

            if (success) {
              // webflow success
              setWebflowUpdateStatusText(
                t("geduDetails.webflow.updateSuccess")
              );
            } else {
              // webflow error
              console.log("Webflow error: " + e.webflow.error);
              setWebflowUpdateStatusText(e.webflow.error);
            }
          }
          refetch();
        },
      }
    );
  };

  const handleActivateDeactivate = () => {
    const data = {
      isActive: !userData.isActive,
    };

    updateGeduProfileData(data, true);
  };

  const updateGeduFilteringData = (data) => {
    console.log(data);

    const languageIds = [];
    for (const language of data.languageData) {
      languageIds.push(language.value);
    }

    const skillIds = [];
    for (const skill of data.skillData) {
      skillIds.push(skill.value);
    }

    const qualificationIds = [];
    for (const qualification of data.qualifiacationData) {
      qualificationIds.push(qualification.value);
    }

    const locationIds = [];
    for (const location of data.locationData) {
      locationIds.push(location.value);
    }

    const attandanceTypeIds = [];
    for (const attandanceType of data.attandanceTypeData) {
      attandanceTypeIds.push(attandanceType.value);
    }

    const body = {
      languageIds: languageIds,
      skillIds: skillIds,
      qualificationIds: qualificationIds,
      locationIds: locationIds,
      attandanceTypeIds: attandanceTypeIds,
    };

    console.log(body);
    mutateFilteringData(
      {
        id: geduId,
        body: body,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          refetch();
        },
      }
    );
  };

  const setCriminalRecordChecked = () => {
    mutateCriminalRecordStatusChecked(
      {
        id: geduId,
        body: { status: true },
      },
      {
        onSuccess: (e) => {
          console.log(e);
          refetch();
        },
      }
    );
  };

  return (
    <ContainerBox>
      <Typography variant="h4">{t("geduDetails.title")}</Typography>
      {errorFilteringDataUpdate && (
        <ErrorAlert txt={errorFilteringDataUpdate} />
      )}
      {isLoading && <CircularProgress />}
      {userData?.id && (
        <>
          <Card key={geduId} sx={{ marginTop: 2 }}>
            <CardContent>
              <Stack
                key={"geduStack1"}
                direction="row"
                alignItems="center"
                gap={2}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  {userData.avatar && (
                    <CardMedia
                      component="img"
                      className="user-icon"
                      image={userData.avatar}
                      alt="Avatar"
                      key={"geduCardMedia1."}
                    />
                  )}
                  {!userData.avatar && <NoAvatarIcon className="user-icon" />}
                  <GeneralTextFieldEditor
                    title={t("geduDetails.setDiscordId")}
                    data={{
                      discordUserId: {
                        value: userData.discordUserId
                          ? userData.discordUserId
                          : "",
                        label: t("geduDetails.discordIdLabel"),
                      },
                    }}
                    buttonText={t("geduDetails.discordIdButtonText")}
                    onSave={(data) => {
                      console.log(data);
                      updateUserData(data);
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ m: 2, position: "relative" }}
                    onClick={() => handleActivateDeactivate()}
                  >
                    {(isFetching || profileDataIsLoading) && (
                      <CircularProgress
                        style={{
                          color: "white",
                          position: "absolute", // Set position to absolute
                          top: "50%", // Position at the center vertically
                          left: "50%", // Position at the center horizontally
                          marginTop: "-10px", // Adjust based on half of the CircularProgress size
                          marginLeft: "-10px",
                        }}
                        size={20}
                        thickness={3}
                        mr={20}
                      />
                    )}
                    {userData.isActive
                      ? t("geduDetails.deactivateButtonText")
                      : t("geduDetails.activateButtonText")}
                  </Button>
                </Stack>
                <Stack
                  key={"geduStack2"}
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap={1}
                >
                  <Typography variant="h6">
                    {userData.name}
                    {" ["}
                    <span
                      style={{ color: userData.isActive ? "green" : "red" }}
                    >
                      {userData.isActive
                        ? t("geduDetails.active")
                        : t("geduDetails.inactive")}
                    </span>
                    {"]"}
                  </Typography>
                  <Typography variant="body1">
                    {t("geduDetails.userId")}: {userData.id}
                  </Typography>
                  <Typography variant="body1">
                    {userData.discordUserName
                      ? `${userData.discordUserName}`
                      : ``}
                    {userData.discordUserId
                      ? " [" + userData.discordUserId + "]"
                      : ``}
                  </Typography>
                  <Typography variant="body1">
                    {userData.email}
                    {userData.phoneNumber ? ` - ${userData.phoneNumber}` : ``}
                  </Typography>
                  <Typography variant="body2">
                    {t("geduDetails.roles")}: {userData.roles}
                  </Typography>
                  {/* Add more fields as needed */}
                  <Typography variant="body2">
                    {t("geduDetails.lastLogin")}: {userData.lastLogin}
                  </Typography>
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap={1}
                    sx={{ marginTop: 2 }}
                  >
                    <>
                      {userData.criminalRecordCheck &&
                        userData.criminalRecordCheck.status && (
                          <>
                            <Typography
                              variant="body2"
                              style={{
                                color: userData.criminalRecordCheck.textColor,
                              }}
                            >
                              {t("geduDetails.criminalRecordChecked")}:{" "}
                              {userData.criminalRecordCheck.checkedAt}
                              {" ("}
                              {userData.criminalRecordCheck.lastName}
                              {", "}
                              {userData.criminalRecordCheck.firstName}
                              {")"}
                            </Typography>
                            <Button
                              variant="outlined"
                              sx={{ m: 0 }}
                              onClick={() => setCriminalRecordChecked()}
                            >
                              {t("geduDetails.updateCriminalRecordChecked")}
                            </Button>
                          </>
                        )}
                      {!userData.criminalRecordCheck?.status && (
                        <>
                          <Typography variant="body2">
                            {t("geduDetails.criminalRecordChecked")}:{" "}
                            {t("geduDetails.criminalRecordNotChecked")}
                          </Typography>
                          <Button
                            variant="outlined"
                            sx={{ m: 0 }}
                            onClick={() => setCriminalRecordChecked()}
                          >
                            {t("geduDetails.setCriminalRecordChecked")}
                          </Button>
                        </>
                      )}
                    </>
                  </Stack>
                  {isAdmin(appUserRoles) && (
                    <ResetPasswordDialog userId={geduId} />
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>

          <MuiAccordion key="geduInvoicingData">
            <MuiAccordionSummary
              aria-controls="panel1d-content-geduIncoicimView"
              id="panel1d-header-geduInvoicingView"
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("geduDetails.invoicingDataTitle")}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails sx={{ display: "block", textAlign: "left" }}>
              {errorCodeDomains && <ErrorAlert txt={errorCodeDomains} />}
              {(isFetching || profileDataIsLoading) && <CircularProgress />}
              <Typography variant="body1" sx={{ m: 2 }}>
                {t("geduDetails.invoicingMethod")}:{" "}
                {userData.invoicingData.invoicingMethodName}
              </Typography>
              <Typography variant="body1" sx={{ m: 2 }}>
                {t("geduDetails.invoicingEmail")}:{" "}
                {userData.invoicingData.invoicingEmail}
              </Typography>
              <GeduInvoicingInfoEdit
                sx={{ m: 2 }}
                geduBillingMethods={geduBillingMethods}
                geduData={userData}
                isOpen={changeBillingMethodOpen}
                setIsOpen={setChangeBillingMethodOpen}
                currentBillingMethod={userData.invoicingData.invoicingMethodId}
                currentBillingEmail={userData.invoicingData.invoicingEmail}
                saveBillingInformation={(
                  newBillingMethodId,
                  newBillingEmail
                ) => {
                  console.log(newBillingMethodId);
                  console.log(newBillingEmail);
                  updateGeduProfileData({
                    invoicingMethodId: newBillingMethodId,
                    invoicingEmail: newBillingEmail,
                  });
                }}
              />
            </MuiAccordionDetails>
          </MuiAccordion>
          <MuiAccordion key="geduFilteringData">
            <MuiAccordionSummary
              aria-controls="panel1d-content-geduFilteringView"
              id="panel1d-header-geduFilteringView"
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("geduDetails.filteringDataTitle")}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails sx={{ display: "block", textAlign: "left" }}>
              {(isFetching || filteringDataIsLoading) && <CircularProgress />}
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body1" sx={{ m: 2 }}>
                  {t("geduDetails.languages")}:{" "}
                </Typography>
                {selectedLanguages.length > 0 && (
                  <Stack direction="row" alignItems="center" gap={1}>
                    {selectedLanguages.length > 0 &&
                      selectedLanguages.map((language, index) => (
                        <Typography variant="body1" key={"language" + index}>
                          {language.label}
                        </Typography>
                      ))}
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body1" sx={{ m: 2 }}>
                  {t("geduDetails.skills")}:{" "}
                </Typography>
                {selectedSkills.length > 0 && (
                  <Stack direction="row" alignItems="center" gap={1}>
                    {selectedSkills.length > 0 &&
                      selectedSkills.map((skill, index) => (
                        <Typography variant="body1" key={"skill" + index}>
                          {skill.label}
                        </Typography>
                      ))}
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body1" sx={{ m: 2 }}>
                  {t("geduDetails.qualifications")}:{" "}
                </Typography>
                {selectedQualifications.length > 0 && (
                  <Stack direction="row" alignItems="center" gap={1}>
                    {selectedQualifications.length > 0 &&
                      selectedQualifications.map((qualification, index) => (
                        <Typography
                          variant="body1"
                          key={"qualification" + index}
                        >
                          {qualification.label}
                        </Typography>
                      ))}
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body1" sx={{ m: 2 }}>
                  {t("geduDetails.locations")}:{" "}
                </Typography>
                {selectedLocations.length > 0 && (
                  <Stack direction="row" alignItems="center" gap={1}>
                    {selectedLocations.length > 0 &&
                      selectedLocations.map((location, index) => (
                        <Typography variant="body1" key={"location" + index}>
                          {location.label}
                        </Typography>
                      ))}
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body1" sx={{ m: 2 }}>
                  {t("geduDetails.attandanceTypes")}:{" "}
                </Typography>
                {selectedAttendanceTypes.length > 0 && (
                  <Stack direction="row" alignItems="center" gap={1}>
                    {selectedAttendanceTypes.length > 0 &&
                      selectedAttendanceTypes.map((attandanceType, index) => (
                        <Typography
                          variant="body1"
                          key={"attandanceType" + index}
                        >
                          {attandanceType.label}
                        </Typography>
                      ))}
                  </Stack>
                )}
              </Stack>
              <GeduFilteringDataEdit
                sx={{ m: 2 }}
                geduData={userData}
                isOpen={changeFilteringDataOpen}
                setIsOpen={setFilteringDataOpen}
                geduLanguages={geduLanguages}
                selectedLanguages={selectedLanguages}
                geduSkills={geduSkills}
                selectedSkills={selectedSkills}
                geduQualifications={geduQualifications}
                selectedQualifications={selectedQualifications}
                geduAttendanceTypes={geduAttendanceTypes}
                selectedAttendanceTypes={selectedAttendanceTypes}
                geduLocations={geduLocations}
                selectedLocations={selectedLocations}
                saveFilteringData={(
                  languages,
                  skills,
                  qualifications,
                  locations,
                  attandanceTypes
                ) => {
                  console.log(languages);
                  updateGeduFilteringData({
                    languageData: languages,
                    skillData: skills,
                    qualifiacationData: qualifications,
                    locationData: locations,
                    attandanceTypeData: attandanceTypes,
                  });
                }}
              />
            </MuiAccordionDetails>
          </MuiAccordion>
          <MuiAccordion key="geduClubData">
            <MuiAccordionSummary
              aria-controls="panel1d-content-geduClubView"
              id="panel1d-header-gedClubView"
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("geduDetails.clubsTitle")}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List key={"ownClubs"}>
                {ownClubsData.map((club, index) => (
                  <React.Fragment key={"ownClub" + club.id + index}>
                    <ListItem key={"ownClubLine" + club.id + index}>
                      <OpenIcon
                        size="14"
                        onClick={() => openClub(club.id)}
                        sx={{ mr: 2 }}
                      />
                      <ListItemText
                        primary={`${club.name} - ${club.meetings}`}
                        secondary={`${t("geduDetails.role")}: ${club.role} - ${
                          club.groupName
                            ? t("geduDetails.groupName") +
                              ": " +
                              club.groupName +
                              " - "
                            : ""
                        }${t("geduDetails.clubId")}: ${club.id}`}
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion>
          {false && (
            <MuiAccordion key="geduWebflowData">
              <MuiAccordionSummary
                aria-controls="panel1d-content-geduWebflowView"
                id="panel1d-header-gedWebflowView"
                expandIcon={<ExpandMoreIcon />}
                sx={{ background: "#f0f0f0" }}
              >
                <Typography>{t("geduDetails.webFlowTitle")}</Typography>
              </MuiAccordionSummary>
              {userData.webflowGeduId && (
                <>
                  {webflowUpdateStatusText && (
                    <Stack direction="column" alignItems="flex-start" gap={0.5}>
                      <Typography
                        variant="body1"
                        sx={{ color: webflowUpdateSuccess ? "green" : "red" }}
                      >
                        {t("geduDetails.webflow.updateStatus")}:{" "}
                        {webflowUpdateStatusText}
                      </Typography>
                    </Stack>
                  )}

                  <MuiAccordionDetails>
                    <Stack direction="column" alignItems="flex-start" gap={0.5}>
                      <Typography variant="body1">
                        {t("geduDetails.webflow.id")}: {userData.webflowGeduId}
                      </Typography>
                      <Typography variant="body1">
                        {t("geduDetails.webflow.name")}: {userData.webflowName}
                      </Typography>
                      <Typography variant="body1">
                        {t("geduDetails.webflow.discord")}:{" "}
                        {userData.discordUserName}
                      </Typography>
                      <Typography variant="body1">
                        {t("geduDetails.webflow.email")}: {userData.email}
                      </Typography>
                      <Typography variant="body1">
                        {t("geduDetails.webflow.isActive")}:{" "}
                        {userData.isActive
                          ? t("general.dialog.yes")
                          : t("general.dialog.no")}
                      </Typography>
                    </Stack>

                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      gap={0.5}
                      sx={{ mt: 3 }}
                    >
                      <Typography variant="body1">
                        {t("geduDetails.webflow.imageLink")}:{" "}
                        {userData.publicImageLink}
                      </Typography>
                      <Typography variant="body1">
                        {t("geduDetails.webflow.description")}:{" "}
                        {userData.publicDescription}
                      </Typography>
                      <Typography variant="body1">
                        {t("geduDetails.webflow.isExperienceArchitect")}:{" "}
                        {userData.isExperienceArchitect
                          ? t("general.dialog.yes")
                          : t("general.dialog.no")}
                      </Typography>
                    </Stack>
                    <GeduWebflowInfoEdit
                      sx={{ m: 2 }}
                      geduData={userData}
                      isOpen={changeWebflowOpen}
                      setIsOpen={setChangeWebflowOpen}
                      saveWebflowData={(
                        newDescription,
                        newImageLink,
                        newIsExperienceArchitect,
                        createProfile
                      ) => {
                        console.log(newDescription);
                        console.log(newImageLink);
                        console.log(newIsExperienceArchitect);
                        updateGeduProfileData(
                          {
                            publicDescription: newDescription,
                            publicImageLink: newImageLink,
                            isExperienceArchitect: newIsExperienceArchitect,
                            createProfile: createProfile,
                          },
                          true
                        ); // update webflow flag = true
                      }}
                    />
                  </MuiAccordionDetails>
                </>
              )}
              {!userData.webflowGeduId && (
                <>
                  <MuiAccordionDetails>
                    {webflowUpdateStatusText && (
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        gap={0.5}
                      >
                        <Typography
                          variant="body1"
                          sx={{ color: webflowUpdateSuccess ? "green" : "red" }}
                        >
                          {t("geduDetails.webflow.updateStatus")}:{" "}
                          {webflowUpdateStatusText}
                        </Typography>
                      </Stack>
                    )}
                    {
                      // add create webflow gedu profile button
                    }
                    <GeduWebflowInfoEdit
                      sx={{ m: 2 }}
                      geduData={userData}
                      isOpen={changeWebflowOpen}
                      setIsOpen={setChangeWebflowOpen}
                      saveWebflowData={(
                        newDescription,
                        newImageLink,
                        newIsExperienceArchitect,
                        createProfile
                      ) => {
                        console.log(newDescription);
                        console.log(newImageLink);
                        console.log(newIsExperienceArchitect);
                        updateGeduProfileData(
                          {
                            publicDescription: newDescription,
                            publicImageLink: newImageLink,
                            isExperienceArchitect: newIsExperienceArchitect,
                            createProfile: createProfile,
                          },
                          true
                        ); // update webflow flag = true
                      }}
                      create={true}
                    />
                  </MuiAccordionDetails>
                </>
              )}
            </MuiAccordion>
          )}
        </>
      )}
    </ContainerBox>
  );
}

const GeduInvoicingInfoEdit = ({
  geduBillingMethods,
  geduData,
  currentBillingMethod,
  currentBillingEmail,
  saveBillingInformation,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();

  const [selectedBillingMethod, setSelectedBillingMethod] = useState(
    currentBillingMethod ? currentBillingMethod : ""
  );
  const [billingEmail, setBillingEmail] = useState(
    currentBillingEmail ? currentBillingEmail : ""
  );

  useEffect(() => {
    if (isOpen) {
      setBillingEmail(currentBillingEmail ? currentBillingEmail : "");
      setSelectedBillingMethod(
        currentBillingMethod ? currentBillingMethod : ""
      );
    }
  }, [isOpen, currentBillingEmail, currentBillingMethod]);

  const [billingMethodInputValue, setBillingMethodInputValue] = useState("");

  const handleClose = () => {
    setSelectedBillingMethod("");
    setBillingEmail("");
    setIsOpen(false);
    setBillingMethodInputValue("");
  };

  return (
    <>
      <Button variant="contained" onClick={() => setIsOpen(true)} sx={{ m: 2 }}>
        {t("geduDetails.GeduInvoicingInfoEdit.setInvoicingData")}
      </Button>
      <Dialog
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          sx: {
            minHeight: "50%",
            maxHeight: "50%",
          },
        }}
        disableScrollLock
      >
        <DialogTitle>
          {t("geduDetails.GeduInvoicingInfoEdit.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("geduDetails.GeduInvoicingInfoEdit.description")}
          </DialogContentText>
          <DialogContentText>
            <br />
          </DialogContentText>
          <DialogContentText>{geduData.name}</DialogContentText>
          <DialogContentText>{geduData.discordUserName}</DialogContentText>
          <DialogContentText>
            <br />
          </DialogContentText>
          <Select
            id="select-status"
            size="small"
            sx={{ m: 1, width: "100%" }}
            value={selectedBillingMethod ? selectedBillingMethod : ""}
            onChange={(e) => {
              console.log(e.target.value);
              setSelectedBillingMethod(e.target.value);
            }}
          >
            {geduBillingMethods.length > 0 &&
              geduBillingMethods.map((code, i) => (
                <MenuItem key={i} value={code.value}>
                  {code.label}
                </MenuItem>
              ))}
          </Select>

          <TextField
            label={t("geduDetails.GeduInvoicingInfoEdit.invoicingEmailLabel")}
            variant="outlined"
            fullWidth
            multiline={false}
            value={billingEmail}
            onChange={(event) => {
              setBillingEmail(event.target.value);
            }}
            sx={{ m: 1, width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("general.dialog.cancel")}</Button>
          <Button
            variant="contained"
            disabled={selectedBillingMethod?.id === currentBillingMethod}
            onClick={async () => {
              await saveBillingInformation(selectedBillingMethod, billingEmail);
              handleClose();
            }}
          >
            {t("participants.changeGamerGroup.changeButtonText")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const GeduFilteringDataEdit = ({
  geduData,
  geduLanguages,
  selectedLanguages,
  geduSkills,
  selectedSkills,
  geduQualifications,
  geduAttendanceTypes,
  selectedQualifications,
  selectedAttendanceTypes,
  geduLocations,
  selectedLocations,
  saveFilteringData,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();

  const [selectedLocalLanguages, setSelectedLocalLanguages] =
    useState(selectedLanguages);
  const [selectedLocalSkills, setSelectedLocalSkills] =
    useState(selectedLanguages);
  const [selectedLocalQualifications, setSelectedLocalQualifications] =
    useState(selectedQualifications);
  const [selectedLocalAttandanceTypes, setSelectedLocalAttandanceTypes] =
    useState(selectedAttendanceTypes);
  const [selectedLocalLocations, setSelectedLocalLocations] =
    useState(selectedLocations);

  useEffect(() => {
    if (isOpen) {
      setSelectedLocalLanguages(selectedLanguages);
      setSelectedLocalSkills(selectedSkills);
      setSelectedLocalQualifications(selectedQualifications);
      setSelectedLocalLocations(selectedLocations);
      setSelectedLocalAttandanceTypes(selectedAttendanceTypes);
    }
  }, [
    isOpen,
    selectedLanguages,
    selectedSkills,
    selectedQualifications,
    selectedLocations,
    selectedAttendanceTypes,
  ]);

  const handleClose = () => {
    setSelectedLocalLanguages(selectedLanguages);
    setSelectedLocalSkills(selectedSkills);
    setSelectedLocalQualifications(selectedQualifications);
    setSelectedLocalLocations(selectedLocations);
    setSelectedLocalAttandanceTypes(selectedAttendanceTypes);
    setIsOpen(false);
  };

  const dialogHasChanges = () => {
    if (selectedLocalLanguages.length !== selectedLanguages.length) return true;

    for (let i = 0; i < selectedLocalLanguages.length; i++) {
      if (selectedLocalLanguages[i].value !== selectedLanguages[i].value)
        return true;
    }

    if (selectedLocalSkills.length !== selectedSkills.length) return true;

    for (let i = 0; i < selectedLocalSkills.length; i++) {
      if (selectedLocalSkills[i].value !== selectedSkills[i].value) return true;
    }

    if (selectedLocalQualifications.length !== selectedQualifications.length)
      return true;

    for (let i = 0; i < selectedLocalQualifications.length; i++) {
      if (
        selectedLocalQualifications[i].value !== selectedQualifications[i].value
      )
        return true;
    }

    for (let i = 0; i < selectedLocalAttandanceTypes.length; i++) {
      if (
        selectedLocalAttandanceTypes[i].value !==
        selectedAttendanceTypes[i].value
      )
        return true;
    }

    if (selectedLocalLocations.length !== selectedLocations.length) return true;

    for (let i = 0; i < selectedLocalLocations.length; i++) {
      if (selectedLocalLocations[i].value !== selectedLocations[i].value)
        return true;
    }

    return false;
  };

  return (
    <>
      <Button variant="contained" onClick={() => setIsOpen(true)} sx={{ m: 2 }}>
        {t("geduDetails.GeduFilteringDataEdit.setFilteringDataButton")}
      </Button>
      <Dialog
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          sx: {
            minHeight: "80%",
            maxHeight: "80%",
          },
        }}
        disableScrollLock
      >
        <DialogTitle>
          {t("geduDetails.GeduFilteringDataEdit.title")}
        </DialogTitle>
        <DialogContent sx={{}}>
          <DialogContentText>
            {t("geduDetails.GeduFilteringDataEdit.description")}
          </DialogContentText>
          <DialogContentText>
            <br />
          </DialogContentText>
          <DialogContentText>
            {geduData.name} [{geduData.discordUserName}]
          </DialogContentText>
          <DialogContentText>
            <br />
          </DialogContentText>
          <Typography variant="body1">{t("geduDetails.languages")}</Typography>
          <ReactSelect
            sx={{}}
            name="languageSelector"
            options={geduLanguages}
            className="gedufilter-multi-select"
            placeholder={t(
              "geduDetails.GeduFilteringDataEdit.languageSelectorPlaceholder"
            )}
            value={selectedLocalLanguages}
            onChange={(data) => {
              setSelectedLocalLanguages(data);
            }}
            isSearchable={true}
            isMulti
            disabled={true}
          />
          <Typography variant="body1">{t("geduDetails.skills")}</Typography>
          <ReactSelect
            name="skillSelector"
            options={geduSkills}
            className="gedufilter-multi-select"
            placeholder={t(
              "geduDetails.GeduFilteringDataEdit.skillSelectorPlaceholder"
            )}
            value={selectedLocalSkills}
            onChange={(data) => {
              setSelectedLocalSkills(data);
            }}
            isSearchable={true}
            isMulti
            disabled={true}
          />
          <Typography variant="body1">
            {t("geduDetails.qualifications")}
          </Typography>
          <ReactSelect
            name="qualificationSelector"
            options={geduQualifications}
            className="gedufilter-multi-select"
            placeholder={t(
              "geduDetails.GeduFilteringDataEdit.qualificationSelectorPlaceholder"
            )}
            value={selectedLocalQualifications}
            onChange={(data) => {
              setSelectedLocalQualifications(data);
            }}
            isSearchable={true}
            isMulti
            disabled={true}
          />
          <Typography variant="body1">{t("geduDetails.locations")}</Typography>
          <ReactSelect
            name="locationSelector"
            options={geduLocations}
            className="gedufilter-multi-select"
            placeholder={t(
              "geduDetails.GeduFilteringDataEdit.locationSelectorPlaceholder"
            )}
            value={selectedLocalLocations}
            onChange={(data) => {
              setSelectedLocalLocations(data);
            }}
            isSearchable={true}
            isMulti
            disabled={true}
          />
          <Typography variant="body1">
            {t("geduDetails.attandanceTypes")}
          </Typography>
          <ReactSelect
            name="attandanceTypeSelector"
            options={geduAttendanceTypes}
            className="gedufilter-multi-select"
            placeholder={t(
              "geduDetails.GeduFilteringDataEdit.attandanceTypeSelectorPlaceholder"
            )}
            value={selectedLocalAttandanceTypes}
            onChange={(data) => {
              setSelectedLocalAttandanceTypes(data);
            }}
            isSearchable={true}
            isMulti
            disabled={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("general.dialog.cancel")}</Button>
          <Button
            variant="contained"
            disabled={!dialogHasChanges}
            onClick={async () => {
              await saveFilteringData(
                selectedLocalLanguages,
                selectedLocalSkills,
                selectedLocalQualifications,
                selectedLocalLocations,
                selectedLocalAttandanceTypes
              );
              handleClose();
            }}
          >
            {t("geduDetails.GeduFilteringDataEdit.setNewValuesButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const GeduWebflowInfoEdit = ({
  geduData,
  saveWebflowData,
  isOpen,
  setIsOpen,
  create = false,
}) => {
  const { t } = useTranslation();

  const [createProfile, setCreateProfile] = useState(create);

  const descriptionMaxLength = 2000;
  const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(0);

  const imageLinkMaxLength = 500;
  const [imageLinkCharacterCount, setImageLinkCharacterCount] = useState(0);

  // data that can be edited in this dialog
  const [description, setDescription] = useState(
    geduData?.description ? geduData.description : ""
  );
  const [imageLink, setImageLink] = useState(
    geduData?.imageLink ? geduData.imageLink : ""
  );
  const [isExperienceArchitect, setIsExperienceArchitect] = useState(
    geduData?.isExperienceArchitect ? geduData.isExperienceArchitect : false
  );

  useEffect(() => {
    if (isOpen && geduData) {
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>USEEFFECT");
      console.log(geduData);

      setDescription(geduData.publicDescription);
      setDescriptionCharacterCount(
        geduData.publicDescription ? geduData.publicDescription.length : 0
      );
      setImageLink(geduData.publicImageLink);
      setImageLinkCharacterCount(
        geduData.publicImageLink ? geduData.publicImageLink.length : 0
      );
      setIsExperienceArchitect(geduData.isExperienceArchitect);
    }
  }, [isOpen, geduData]);

  const handleClose = () => {
    setDescription("");
    setDescriptionCharacterCount(0);
    setImageLink("");
    setIsExperienceArchitect(false);
    setIsOpen(false);
  };

  const handleDescriptionChange = (newValue) => {
    setDescriptionCharacterCount(newValue.length);
    if (newValue.length <= descriptionMaxLength) {
      setDescription(newValue);
    }
  };

  const urlRegex = /^(http|https):\/\/\S+$/;

  const handleImageLinkChange = (newValue) => {
    setImageLinkCharacterCount(newValue.length);
    if (newValue.length <= imageLinkMaxLength) {
      setImageLink(newValue);
    }
  };

  const enableSave = () => {
    // description and imageLink has been defined and has changes

    // check if description is defined
    if (!description) return false;
    if (description === "") return false;

    // check if imageLink is defined
    if (!imageLink) return false;
    if (imageLink === "") return false;

    // check if the values have been changed
    if (description !== geduData.description) return true;
    if (imageLink !== geduData.imageLink) return true;
    if (isExperienceArchitect !== geduData.isExperienceArchitect) return true;

    return false;
  };

  return (
    <>
      {createProfile && (
        <Button
          variant="contained"
          onClick={() => setIsOpen(true)}
          sx={{ m: 2 }}
        >
          {t("geduDetails.webflowInformationEdit.createWebflowDataButtonText")}
        </Button>
      )}
      {!createProfile && (
        <Button
          variant="contained"
          onClick={() => setIsOpen(true)}
          sx={{ m: 2 }}
        >
          {t("geduDetails.webflowInformationEdit.setWebflowDataButtonText")}
        </Button>
      )}

      <Dialog
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          sx: {
            minHeight: "50%",
            maxHeight: "75%",
          },
        }}
        disableScrollLock
      >
        <DialogTitle>
          {t("geduDetails.webflowInformationEdit.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("geduDetails.webflowInformationEdit.description")}
          </DialogContentText>
          <DialogContentText>
            <br />
          </DialogContentText>
          <DialogContentText>
            {t("geduDetails.webflow.name")}: {geduData.webflowName}
          </DialogContentText>
          <DialogContentText>
            {t("geduDetails.webflow.discord")}: {geduData.discordUserName}
          </DialogContentText>
          <DialogContentText>
            {t("geduDetails.webflow.email")}: {geduData.email}
          </DialogContentText>
          <DialogContentText>
            {t("geduDetails.webflow.isActive")}:{" "}
            {geduData.isActive
              ? t("general.dialog.yes")
              : t("general.dialog.no")}
          </DialogContentText>
          <DialogContentText>
            <br />
          </DialogContentText>

          <Stack direction="column" spacing={0.5} alignItems="flex-end">
            <TextField
              label={t("geduDetails.webflowInformationEdit.descriptionLabel")}
              variant="outlined"
              fullWidth
              multiline={true}
              rows={4}
              value={description}
              onChange={(event) => {
                handleDescriptionChange(event.target.value);
              }}
              inputProps={{ maxLength: descriptionMaxLength }}
              sx={{ m: 1, width: "100%" }}
            />
            <Typography variant="caption">
              {descriptionCharacterCount}/{descriptionMaxLength}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={0.5} alignItems="flex-end">
            <TextField
              label={t("geduDetails.webflowInformationEdit.imageLinkLabel")}
              variant="outlined"
              fullWidth
              multiline={false}
              value={imageLink}
              onChange={(event) => {
                handleImageLinkChange(event.target.value);
              }}
              inputProps={{ maxLength: imageLinkMaxLength }}
              sx={{ m: 1, width: "100%" }}
            />
            <Typography variant="caption">
              {imageLinkCharacterCount}/{imageLinkMaxLength}
            </Typography>
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                checked={isExperienceArchitect}
                onChange={(event) =>
                  setIsExperienceArchitect(event.target.checked)
                }
              />
            }
            label={t(
              "geduDetails.webflowInformationEdit.isExperienceArchitectLabel"
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("general.dialog.cancel")}</Button>
          <Button
            variant="contained"
            disabled={!enableSave()}
            onClick={async () => {
              await saveWebflowData(
                description,
                imageLink,
                isExperienceArchitect,
                createProfile
              );
              handleClose();
            }}
          >
            {t("geduDetails.webflowInformationEdit.saveButtonText")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
