import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { CheckCircle, Error } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { setPassword } from "../Hooks/users";

const ResetPasswordDialog = ({ userId }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [password, setPasswordValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [statusMessage, setStatusMessage] = useState(null); // For success/failure message

  const handleOpen = () => {
    setOpen(true);
    setError("");
    setStatusMessage(null); // Clear any existing status messages
  };

  const handleClose = () => {
    if (!loading) {
      setOpen(false);
      setPasswordValue("");
      setError("");
    }
  };

  const handleOk = async () => {
    if (!password) {
      setError(t("geduDetails.resetPassword.errorEmptyPassword"));
      return;
    }

    setLoading(true);
    setError("");
    setStatusMessage(null); // Clear status message during loading

    try {
      // Call the setPassword function with the required JSON structure
      await setPassword({
        id: userId,
        body: { password },
      });

      setStatusMessage({
        type: "success",
        message: t("geduDetails.resetPassword.successMessage"),
      });
      handleClose(); // Close the dialog after successful password update
    } catch (err) {
      setError(t("geduDetails.resetPassword.errorGeneric"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Button to open dialog */}
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          {t("geduDetails.resetPassword.openButton")}
        </Button>

        {/* Success/Failure message with icon */}
        {statusMessage && (
          <Stack direction="row" alignItems="center" spacing={1}>
            {statusMessage.type === "success" ? (
              <CheckCircle style={{ color: "green" }} />
            ) : (
              <Error style={{ color: "red" }} />
            )}
            <Typography
              variant="body2"
              color={statusMessage.type === "success" ? "green" : "red"}
            >
              {statusMessage.message}
            </Typography>
          </Stack>
        )}
      </Stack>

      {/* Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("geduDetails.resetPassword.title")}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {/* Textbox */}
            <TextField
              fullWidth
              label={t("geduDetails.resetPassword.newPasswordLabel")}
              type="password"
              value={password}
              onChange={(e) => setPasswordValue(e.target.value)}
              error={!!error}
              helperText={error}
              disabled={loading}
            />

            {/* Note */}
            <Typography variant="body2" color="textSecondary">
              {t("geduDetails.resetPassword.note")}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          {/* Cancel and OK Buttons */}
          <Button onClick={handleClose} color="secondary" disabled={loading}>
            {t("geduDetails.resetPassword.cancelButton")}
          </Button>
          <Button onClick={handleOk} color="primary" disabled={loading}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              t("geduDetails.resetPassword.okButton")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetPasswordDialog;
